<template>
  <div class="main-container">
    <!-- 面包屑 -->
    <div class="question-nav">
      <router-link :to="{name:'Home'}">首页</router-link>
      <span class="slash"> > </span>
      <span class="current">新闻资讯</span>
    </div>
    <div class="recommend" v-if="recommend.first.id">
      <router-link :to="{name:'NewsShow',params:{id:recommend.first.id}}">
        <div
          class="recommend-left f-left"
          :style="{backgroundImage:'url('+recommend.first.thumb_img+')'}"
        >
          <div class="recommend-title" :title="recommend.first.title">{{recommend.first.title}}</div>
        </div>
      </router-link>
      <div class="recommend-right f-right">
        <router-link
          :to="{name:'NewsShow',params:{id:item.id}}"
          v-for="item in recommend.lastFour"
          :key="item.id"
          class="f-left recommend-right-link"
        >
          <div class="recommend-right-item" :style="{backgroundImage:'url('+item.thumb_img+')'}">
            <div class="recommend-title" :title="item.title">{{item.title}}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="clear news">
      <div class="f-left news-list">
        <div class="news-nav-container">
          <ul class="clear news-nav-wrap">
            <li
              class="news-nav"
              :class="classify==item.id?'active':''"
              v-for="item in navList"
              :key="item.id"
              @click="changeClassify(item.id)"
              :title="item.name"
            >
              <span class="nav-title">
                {{item.name}}
                <span class="line"></span>
              </span>
            </li>
          </ul>
          <div class="index-nav" v-if="classifyList.length>5">
            <a class="nav-left" @click="navToLeft"></a>
            <a class="nav-right" @click="navToRight"></a>
          </div>
        </div>
        <ul>
          <li class="clear news-item" v-for="item in news" :key="item.id">
            <div class="f-left">
              <router-link :to="{name:'NewsShow',params:{id:item.id}}">
                <img class="news-img" :src="item.thumb_img" />
              </router-link>
            </div>
            <div class="f-left">
              <router-link :to="{name:'NewsShow',params:{id:item.id}}">
                <div class="news-title">{{item.title}}</div>
              </router-link>
              <div class="news-des">{{item.description}}</div>
              <div class="news-footer">
                <img src="@/assets/images/news/read-count@3x.png" style="width:16px;" />
                <span>{{item.click_rate}}</span>
                <span class="line">|</span>
                <img src="@/assets/images/news/clock@3x.png" style="width:11px;" />
                <span>{{item.create_at_str}}</span>
              </div>
            </div>
          </li>
        </ul>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="limit"
          layout="prev, pager, next"
          :total="total"
          v-if="news.length>0"
        ></el-pagination>
        <Empty v-if="news.length==0" title="暂无资讯信息哦！" />
      </div>
      <div class="f-right hot-news">
        <div class="hot-title">
          <img src="@/assets/images/news/hot@2x.png" style="width:13px;" />
          <span>热门资讯</span>
        </div>
        <ul class="top-twos">
          <router-link
            :to="{name:'NewsShow',params:{id:item.id}}"
            v-for="(item,index) in topTwoHot"
            :key="item.id"
          >
            <li class="top-two">
              <img class="top-two-img" :src="item.thumb_img" />
              <img
                class="top-two-rank"
                src="@/assets/images/news/top1@3x.png"
                v-if="index==0"
                style="width:52px;"
              />
              <img
                class="top-two-rank"
                src="@/assets/images/news/top2@3x.png"
                v-else
                style="width:52px;"
              />
            </li>
          </router-link>
        </ul>
        <ul class="hot-list">
          <li class="hot-item clear" v-for="(item,index) in hotNews" :key="item.id">
            <router-link :to="{name:'NewsShow',params:{id:item.id}}">
              <img :src="item.thumb_img" class="f-left hot-img" />
              <div class="f-left hot-item-title" :title="item.title">{{item.title}}</div>
            </router-link>
            <div class="hot-rank">
              <img src="@/assets/images/news/top.png" />
              <span>{{index+3}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "News",
  data() {
    return {
      recommend: {
        first: {},
        lastFour: []
      },
      classifyList: [],
      classify: 0,
      page: 1,
      limit: 10,
      total: 0,
      news: [],
      topTwoHot: [],
      hotNews: [],
      index: 0
    };
  },
  computed: {
    navList() {
      return this.classifyList.slice(this.index, this.index + 5);
    }
  },
  async created() {
    let recommendNews = await this.$http.get("/api/app/information/recommend");
    if (recommendNews.length > 0) {
      this.recommend.first = recommendNews[0];
      this.recommend.lastFour = recommendNews.splice(1, 4);
    }
    let classifyList = await this.$http.get("/api/app/information/classify");
    classifyList.unshift({ name: "全部", id: 0 });
    this.classifyList = classifyList;
    await this.getNews();

    let hotNews = await this.$http.get("/api/app/information/hot");
    this.topTwoHot = hotNews.splice(0, 2);
    this.hotNews = hotNews;
  },
  methods: {
    async changeClassify(classify) {
      this.classify = classify;
      this.page = 1;
      await this.getNews();
    },
    async getNews() {
      let data = await this.$http.post("/api/app/information/index", {
        classify_id: this.classify,
        page: this.page,
        limit: this.limit
      });
      let news = data.list;
      news.forEach(n => {
        n.create_at_str = new Date(n.created_at * 1000).format(
          "yyyy-MM-dd hh:mm"
        );
      });
      this.news = news;
      this.total = data.total;
    },
    async handleCurrentChange(page) {
      this.page = page;
      await this.getNews();
    },
    navToLeft() {
      if (this.index == 0) {
        return;
      }
      this.index--;
    },
    navToRight() {
      if (this.index >= this.classifyList.length - 5) {
        return;
      }
      this.index++;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/news.scss";
</style>