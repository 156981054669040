<template>
  <div class="main-container">
    <div class="question-nav">
      <router-link :to="{name:'News'}">新闻资讯</router-link>
      <span class="slash"> > </span>
      <span class="current">资讯详情</span>
    </div>
    <div class="news-main">
      <div class="news-title">{{news.title}}</div>
       <div class="bdsharebuttonbox f-right share"
            @click="shareNews">
        <a href="javascript:void(0)"
            class="bds_more"
            data-cmd="more"
            style="background-size:14px 14px;background-repeat:no-repeat;"
            :style="{backgroundImage:'url('+share+')'}">
          <span>分享</span>
        </a>
      </div>
      <div>
        <span class="news-click">{{news.click_rate}}浏览</span>
        <span class="news-time">{{news.created_at_str}}</span>
      </div>
      <div class="news-content" v-html="news.content"></div>
    </div>
    <el-dialog title="分享"
               :visible.sync="showShareModal"
               width="400px">
      <vue-qr :text="shareUrl"
              :size="200"
              class="center"></vue-qr>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="showShareModal = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VueQr from "vue-qr";

export default {
  name: "NewsShow",
  components: {
    VueQr
  },
  data() {
    return {
      news: {},
      share: require("@/assets/images/course/share@2x.png"),
      showShareModal:false,
      shareUrl:''
    };
  },
  async created() {
    let id = this.$route.params.id;
    let news = await this.$http.post("/api/app/information/detail", {
      information_id: id
    });
    news.created_at_str = new Date(parseInt(news.created_at) * 1000).format(
      "yyyy-MM-dd hh:mm"
    );
    let div = document.createElement("div");
    div.innerHTML = news.content;
    div.querySelectorAll("img").forEach(el => {
      el.removeAttribute("title");
    });
    news.content = div.innerHTML;
    this.news = news;
    document.title = this.news.title;
  },
  methods:{
   async shareNews(){
      let data = await this.$http.get("/api/app/share/" + this.$route.params.id,{ type: 5 },true);
      this.shareUrl = data.url;
      this.showShareModal = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.question-nav {
    height: 62px;
    line-height: 62px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;

    a {
        color: #333333;
    }
}
.slash {
    padding: 0 8px;
}
.news-main {
  background-color: white;
  padding: 30px;
  margin-bottom: 50px;
}
.news-title {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 8px;
  margin-right: 50px;
}
.news-click,
.news-time {
  font-size: 16px;
  color: rgba(140, 140, 140, 1);
  margin-right: 10px;
}
.news-content {
  padding-top: 25px;
}
.share {
  margin-top: -36px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8c8c8c;
  cursor: pointer;
  a{
    padding-left: 22px;
    color: $primary-color;
  }
}
</style>
<style lang="scss">
.news-content img {
  max-width: 100%;
}
</style>